<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="900px">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} Student-Subjects</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <DualSelects
                    :items="subjects"
                    :title="'Select Subjects'"
                    :label="'Filter Subjects...'"
                    :columnName="'name'"
                    :filter-function="filter"
                    v-model="formData.subjects"
                    :is-open="dialog"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },

    closeDialog: {
      type: Function,
      required: true,
    },
    schoolClass: {
      type: Object,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
    filterSubjects: {
      type: Function,
      required: true,
    },

    subjects: {
      type: Array,
      required: true,
    },
  },

  name: "SchoolClassesDialog",
  data: () => ({
    valid: true,
    isValid: {
      firstName: [
        (v) => !!v || " FirstName required",
        (v) => /.+[^#<>:;,?""*|/]+/.test(v) || "Invalid Character",
      ],
      middleName: [
        (v) => !!v || " MiddleName required",
        (v) => /.+[^#<>:;,?""*|/]+/.test(v) || "Invalid Character",
      ],
      surname: [
        (v) => !!v || " LastName required",
        (v) => /.+[^#<>:;,?""*|/]+/.test(v) || "Invalid Character",
      ],
      gender: [
        (v) => !!v || " you must select gender",
        (v) => /.+[^#<>:;,?""*|/]+/.test(v) || "Invalid Character",
      ],
      stream: [
        (v) => !!v || " you must select stream",
        (v) => /.+[^#<>:;,?""*|/]+/.test(v) || "Invalid Character",
      ],
      description: [(v) => !!v || " Name required"],
      level: [(v) => !!v || " Name required"],
    },
  }),
  methods: {
    filter(data) {
      this.filterSubjects(data);
    },
    add() {
      let data = { ...this.formData };

      data.schoolClass = this.schoolClass;

      this.save(data);
    },

    close() {
      this.closeDialog();
    },

    maxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 3);
      return date.toISOString().substr(0, 10);
    },

    minDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 30);
      return date.toISOString().substr(0, 10);
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Save" : "Add";
    },
  },
  mounted() {},
};
</script>
