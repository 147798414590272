var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"metadata-options"},[_c('v-card-actions',{staticClass:"pa-0"},[_c('page-title',{attrs:{"title":_vm.title}}),_c('v-spacer'),(_vm.selected.length > 0)?_c('AddButton',{attrs:{"create-function":_vm.selected.length > 0 ? _vm.openApproveDialog : _vm.exit,"icon":'mdi-view-grid-plus-outline',"title":'APPROVE SELECTED students',"disabled":_vm.selected.length > 0 ? false : true}}):_vm._e()],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"single-expand":true,"server-items-length":_vm.params.total,"options":_vm.pagination,"items-per-page":_vm.params.size,"show-select":"","item-key":"id","no-data-text":'No data available from this resource',"footer-props":{
        'items-per-page-options': [20, 50, 100, 300, 500, -1],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [(props.indeterminate)?_c('v-simple-checkbox',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"primary","value":props.indeterminate}},'v-simple-checkbox',props,false),on)):_vm._e(),(!props.indeterminate)?_c('v-simple-checkbox',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"primary"}},'v-simple-checkbox',props,false),on)):_vm._e()]}},{key:"item",fn:function(ref){
      var item = ref.item;
      var isExpanded = ref.isExpanded;
      var expand = ref.expand;
return [_c('tr',[_c('td',[_c('v-checkbox',{attrs:{"hide-details":"","disabled":item.isSelectable === false,"readonly":item.isSelectable === false,"value":item},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('td',[_vm._v(_vm._s(item.index))]),_c('td',[_vm._v(_vm._s(item.student_necta_serial))]),_c('td',[_vm._v(_vm._s(item.display_name))]),_c('td',[_vm._v(_vm._s(item.gender.descriptions))]),_c('td',[_vm._v(_vm._s(item.schoolClass.name))]),_c('td',[_vm._v(_vm._s(item.schoolClassStream.name))]),_c('td',[_vm._v(_vm._s(item.academicYear.name))]),_c('td',[_vm._v(_vm._s(item.student ? item.student.subjects_count : ""))]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(!isExpanded)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){expand(!isExpanded);
                    _vm.loadBySubject(item);}}},'v-icon',attrs,false),on),[_vm._v(" mdi-chevron-right ")]):_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return expand(!isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-chevron-down ")])]}}],null,true)},[_c('span',[_vm._v("List of Subjects")])])],1),_c('td',[_c('div',{staticClass:"d-flex"},[(!item.approved)?_c('v-btn',{staticClass:"white--text ml-2",attrs:{"color":"primary","disabled":item.student.subjects_count >= 7 ? false : true},on:{"click":function($event){return _vm.openApprovalDialog(item, 'approving')}}},[_c('v-icon',[_vm._v("mdi-alert-circle-check")]),_vm._v(" Approve ")],1):_vm._e()],1),_c('div',{staticClass:"d-flex"},[(item.approved)?_c('v-btn',{staticClass:"white--text ml-2 mr-2",attrs:{"color":"indigo"},on:{"click":function($event){return _vm.openApprovalDialog(item, 'disapproving')}}},[_c('v-icon',[_vm._v("mdi-alert-circle-check")]),_vm._v(" DisApprove ")],1):_vm._e(),(item.approved)?_c('span',{staticClass:"green--text banner"},[_c('v-icon',{staticClass:"pr-1 mr-1",attrs:{"slot":"icon","color":"green","size":"20"},slot:"icon"},[_vm._v(" mdi-check-all ")]),_vm._v(" APPROVED ")],1):_vm._e()],1)])])]}},{key:"expanded-item",fn:function(ref){
                    var headers = ref.headers;
                    var item = ref.item;
return [_c('td',{staticClass:"pb-5",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"ml-5 pt-5"},[_c('v-card',{attrs:{"outlined":"","flat":"","max-width":"50%"}},[_c('v-card-actions',{staticClass:"pa-5"},[_c('b',[_vm._v("List of Registered Subjects:")]),_c('v-spacer'),(!item.approved)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":'primary',"small":""},on:{"click":function($event){return _vm.openDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus-minus-box")]),_vm._v(" ADD SUBJECTS ")],1)]}}],null,true)},[_c('span',[_vm._v("ADD SUBJECTS")])]):_vm._e()],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headerSubjects,"items":_vm.studentSubjects,"items-per-page":item.subjects ? item.subjects.length : 20,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [(!item.approved)?_c('ConfirmAction',{attrs:{"item":item,"title":'Are you sure to delete?',"confirm-function":_vm.deleteObject,"confirm-icon":'mdi-close',"confirmText":'Yes',"toolTipTitle":'Delete subject'}}):_vm._e()]}}],null,true)})],1)],1)])]}},{key:"top",fn:function(){return [_c('v-card-title',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2","sm":"12","md":"2"}},[_c('v-select',{attrs:{"label":"Select class","items":_vm.schoolClassesLists,"item-text":'schoolClass.name',"return-object":"","required":"","clearable":"","hide-details":""},on:{"change":function($event){return _vm.loadStudents($event)},"click:clear":function($event){return _vm.loadStudents()}},model:{value:(_vm.schoolClass),callback:function ($$v) {_vm.schoolClass=$$v},expression:"schoolClass"}})],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"2","sm":"12","md":"2"}},[_c('v-select',{attrs:{"label":"Filter by Stream","items":_vm.streams,"item-text":'name',"return-object":"","required":"","clearable":"","hide-details":""},on:{"change":function($event){return _vm.loadStudentsByStreams($event)},"click:clear":function($event){return _vm.loadStudentsByStreams()}}})],1),_c('v-spacer'),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","sm":"12","md":"4"}},[(_vm.items.length > 0)?_c('SearchField',{attrs:{"search-function":_vm.searchOptions,"prepend-icon":'mdi-magnify',"label":'Search Students',"append-icon":''}}):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('ActionsButtons',{attrs:{"update-function":_vm.openDialog,"delete-function":_vm.openDeleteDialog,"formData":item,"updateIcon":' mdi-pencil-box-outline',"deleteIcon":'mdi-trash-can-outline',"updateButtonColor":'',"deleteButtonColor":''}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.selected.length > 0)?_c('floating-button',{attrs:{"create-function":_vm.selected.length > 0 ? _vm.openApproveDialog : _vm.exit,"icon":'mdi-view-grid-plus-outline',"title":'',"disabled":_vm.selected.length > 0 ? false : true}}):_vm._e()],1),_c('StudentsSubjectsDialog',{attrs:{"closeDialog":_vm.closeDialog,"isEditing":_vm.isEditing,"formData":_vm.formData,"dialog":_vm.dialog,"save":_vm.save,"loading":_vm.loading,"schoolClass":_vm.schoolClass,"subjects":_vm.subjects,"filterSubjects":_vm.loadSubjects}}),_c('ConfirmDialog',{attrs:{"reject-function":_vm.closeDeleteDialog,"accept-function":_vm.deleteObject,"item":_vm.item,"isOpen":_vm.isOpen,"title":'Are you sure you want to delete this Subject?',"loading":_vm.loading}}),_c('ConfirmDialog',{attrs:{"reject-function":_vm.closeApprovalDialog,"accept-function":_vm.approveSubjects,"item":_vm.item,"isOpen":_vm.isOpenApproval,"title":_vm.approvingTitle,"loading":_vm.loading}}),_c('ConfirmDialog',{attrs:{"reject-function":_vm.closeConfirmDialog,"accept-function":_vm.approveSelected,"item":_vm.selected,"isOpen":_vm.openConfirm,"title":("Are  sure you want confirm " + (_vm.selected.length) + " of " + (_vm.params.total) + " students ?"),"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }