import axios from "axios";
const ENDPOINT = "/api/v1/student-subjects";

const get = async payload => {
  return axios.get(ENDPOINT, { params: payload });
};
const getBySubject = async payload => {
  return axios.get(ENDPOINT + '/subjects-by-student', { params: payload });
};

const create = async payload => {
  return await axios.post(ENDPOINT, payload);
};


const approveStudentSubjects = async payload => {
  return await axios.post(ENDPOINT + '/approve-student-subjects', payload);
};

const bulkApprove = async payload => {
  return await axios.post(ENDPOINT + '/bulk-approve', payload);
};

const update = async payload => {
  return await axios.put(ENDPOINT, payload);
};

const remove = async payload => {

  return axios.post(ENDPOINT + '/delete-subjects', payload);
};

export { get, getBySubject, create, update, remove, approveStudentSubjects, bulkApprove };
