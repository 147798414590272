<template>
  <div class="metadata-options">
    <v-card-actions class="pa-0">
      <page-title :title="title" />
      <v-spacer></v-spacer>

      <AddButton
        :create-function="selected.length > 0 ? openApproveDialog : exit"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'APPROVE SELECTED students'"
        :disabled="selected.length > 0 ? false : true"
        v-if="selected.length > 0"
      />
    </v-card-actions>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :single-expand="true"
        :server-items-length="params.total"
        :options.sync="pagination"
        :items-per-page="params.size"
        show-select
        v-model="selected"
        item-key="id"
        class="elevation-1"
        :no-data-text="'No data available from this resource'"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 300, 500, -1],
        }"
      >
        <template v-slot:header.data-table-select="{ props, on }">
          <v-simple-checkbox
            color="primary"
            v-if="props.indeterminate"
            v-ripple
            v-bind="props"
            :value="props.indeterminate"
            v-on="on"
          ></v-simple-checkbox>
          <v-simple-checkbox
            color="primary"
            v-if="!props.indeterminate"
            v-ripple
            v-bind="props"
            v-on="on"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item="{ item, isExpanded, expand }">
          <tr>
            <td>
              <v-checkbox
                v-model="selected"
                hide-details
                :disabled="item.isSelectable === false"
                :readonly="item.isSelectable === false"
                :value="item"
              ></v-checkbox>
            </td>
            <td>{{ item.index }}</td>
            <td>{{ item.student_necta_serial }}</td>
            <td>{{ item.display_name }}</td>
            <td>{{ item.gender.descriptions }}</td>
            <td>{{ item.schoolClass.name }}</td>
            <td>{{ item.schoolClassStream.name }}</td>
            <td>{{ item.academicYear.name }}</td>

            <td>{{ item.student ? item.student.subjects_count : "" }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!isExpanded"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="
                      expand(!isExpanded);
                      loadBySubject(item);
                    "
                  >
                    mdi-chevron-right
                  </v-icon>
                  <v-icon
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="expand(!isExpanded)"
                  >
                    mdi-chevron-down
                  </v-icon>
                </template>
                <span>List of Subjects</span>
              </v-tooltip>
            </td>
            <td>
              <div class="d-flex">
                <v-btn
                  v-if="!item.approved"
                  color="primary"
                  @click="openApprovalDialog(item, 'approving')"
                  class="white--text ml-2"
                  :disabled="item.student.subjects_count >= 7 ? false : true"
                >
                  <v-icon>mdi-alert-circle-check</v-icon>
                  Approve
                </v-btn>
              </div>
              <div class="d-flex">
                <v-btn
                  v-if="item.approved"
                  color="indigo"
                  @click="openApprovalDialog(item, 'disapproving')"
                  class="white--text ml-2 mr-2"
                >
                  <v-icon>mdi-alert-circle-check</v-icon>
                  DisApprove
                </v-btn>
                <span class="green--text banner" v-if="item.approved">
                  <v-icon slot="icon" color="green" size="20" class="pr-1 mr-1">
                    mdi-check-all
                  </v-icon>
                  APPROVED
                </span>
              </div>
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pb-5">
            <div class="ml-5 pt-5">
              <!--Code:
              <em>
                <b>{{ item.code }}</b>
              </em>-->
              <v-card outlined flat max-width="50%">
                <v-card-actions class="pa-5">
                  <b>List of Registered Subjects:</b>
                  <v-spacer></v-spacer>
                  <v-tooltip top v-if="!item.approved">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :color="'primary'"
                        class="mr-2"
                        @click="openDialog(item)"
                        small
                      >
                        <v-icon> mdi-plus-minus-box</v-icon>
                        ADD SUBJECTS
                      </v-btn>
                    </template>
                    <span>ADD SUBJECTS</span>
                  </v-tooltip>
                </v-card-actions>

                <v-data-table
                  :loading="loading"
                  :headers="headerSubjects"
                  :items="studentSubjects"
                  :items-per-page="item.subjects ? item.subjects.length : 20"
                  hide-default-footer
                  dense
                >
                  <template v-slot:item.actions="{ item }">
                    <ConfirmAction
                      :item="item"
                      :title="'Are you sure to delete?'"
                      :confirm-function="deleteObject"
                      :confirm-icon="'mdi-close'"
                      :confirmText="'Yes'"
                      :toolTipTitle="'Delete subject'"
                      v-if="!item.approved"
                    />
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </td>
        </template>
        <template v-slot:top>
          <v-card-title>
            <v-col cols="2" sm="12" md="2" class="pa-0">
              <v-select
                v-model="schoolClass"
                label="Select class"
                :items="schoolClassesLists"
                :item-text="'schoolClass.name'"
                @change="loadStudents($event)"
                @click:clear="loadStudents()"
                return-object
                required
                clearable
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="2" sm="12" md="2" class="pa-5">
              <v-select
                label="Filter by Stream"
                :items="streams"
                :item-text="'name'"
                @change="loadStudentsByStreams($event)"
                @click:clear="loadStudentsByStreams()"
                return-object
                required
                clearable
                hide-details
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="12" md="4" class="pa-0">
              <SearchField
                :search-function="searchOptions"
                :prepend-icon="'mdi-magnify'"
                :label="'Search Students'"
                :append-icon="''"
                v-if="items.length > 0"
              />
            </v-col>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionsButtons
            :update-function="openDialog"
            :delete-function="openDeleteDialog"
            :formData="item"
            :updateIcon="' mdi-pencil-box-outline'"
            :deleteIcon="'mdi-trash-can-outline'"
            :updateButtonColor="''"
            :deleteButtonColor="''"
          />
        </template>
      </v-data-table>

      <floating-button
        :create-function="selected.length > 0 ? openApproveDialog : exit"
        :icon="'mdi-view-grid-plus-outline'"
        :title="''"
        :disabled="selected.length > 0 ? false : true"
        v-if="selected.length > 0"
      />
    </v-card>

    <StudentsSubjectsDialog
      :closeDialog="closeDialog"
      :isEditing="isEditing"
      :formData="formData"
      :dialog="dialog"
      :save="save"
      :loading="loading"
      :schoolClass="schoolClass"
      :subjects="subjects"
      :filterSubjects="loadSubjects"
    />
    <ConfirmDialog
      :reject-function="closeDeleteDialog"
      :accept-function="deleteObject"
      :item="item"
      :isOpen="isOpen"
      :title="'Are you sure you want to delete this Subject?'"
      :loading="loading"
    />

    <ConfirmDialog
      :reject-function="closeApprovalDialog"
      :accept-function="approveSubjects"
      :item="item"
      :isOpen="isOpenApproval"
      :title="approvingTitle"
      :loading="loading"
    />

    <ConfirmDialog
      :reject-function="closeConfirmDialog"
      :accept-function="approveSelected"
      :item="selected"
      :isOpen="openConfirm"
      :title="
        `Are  sure you want confirm ${selected.length} of ${params.total} students ?`
      "
      :loading="loading"
    />
  </div>
</template>

<script>
import StudentsSubjectsDialog from "./Forms/StudentsSubjectDialog.vue";
import * as subject from "./services";
import { get as getClasses, streams } from "../../school-classes/services";
import { get as getSubjects } from "../../subjects/services";

import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    StudentsSubjectsDialog,
  },
  name: "StudentsSubjects",
  data() {
    return {
      approvingTitle: null,
      isOpenApproval: false,
      selected: [],
      schoolClassesLists: [],
      schoolClass: {},
      formData: {},
      dialog: false,
      isOpen: false,
      isEditing: false,
      loading: false,
      openConfirm: false,
      params: {},
      item: null,
      schoolClassStream: "",
      items: [],
      levels: [],
      streams: [],
      subjects: [],
      isApproved: false,
      student: null,
      title: "Student Subject Management",

      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "IndexNo",
          align: "start",
          sortable: false,
          value: "student_necta_serial",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "student.display_name",
          class: "primary--text",
        },

        {
          text: "Gender",
          align: "start",
          sortable: false,
          value: "student.gender.descriptions",
          class: "primary--text",
        },
        {
          value: "school_class.name",
          class: "primary--text",
        },
        {
          text: "Stream",
          align: "start",
          sortable: false,
          value: "school_class_stream.name",
          class: "primary--text",
        },
        {
          text: "Year",
          align: "start",
          sortable: false,
          value: "academic_year.name",
          class: "primary--text",
        },
        {
          text: "Number of Subjects",
          align: "start",
          sortable: false,
          class: "primary--text",
        },
        {
          text: "View Subjects",
          align: "start",
          sortable: false,
          value: "",
          sortable: false,
          class: "primary--text",
        },
        {
          align: "start",
          sortable: false,
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
    };
  },

  methods: {
    openApproveDialog() {
      this.openConfirm = true;
    },
    approveSelected(data) {
      let params = {
        students: data,
      };

      subject.bulkApprove(params).then((result) => {
        this.selected = [];
        this.init(this.params);
        this.closeConfirmDialog();
      });
    },

    closeConfirmDialog() {
      this.openConfirm = false;
    },
    loadBySubject(data) {
      this.isApproved = data.approved;
      this.student = data;
      let params = {
        studentId: data.student.id,
      };
      this.loading = true;

      subject
        .getBySubject(params)
        .then((response) => {
          this.loading = false;
          this.studentSubjects = response.data
            .sort(function(a, b) {
              return a.code > b.code ? 1 : -1;
            })
            .map((item, index) => ({
              ...item,
              ids: index + 1 + ".",
              code: "0" + item.code,
              approved: data.approved,
            }));
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    searchOption() {},

    init(params) {
      this.loading = true;
      subject
        .get({
          ...params,
        })
        .then((response) => {
          this.loading = false;
          this.params.total = response.data[0].total;
          this.items = response.data[0]
            ? response.data[0].data
                .map((item) => ({
                  ...item,
                  schoolClassStream: item.school_class_stream,
                  schoolClass: item.school_class,
                  first_name: item.student.first_name,
                  middle_name: item.student.middle_name,
                  surname: item.student.surname,
                  display_name: item.student.display_name.toUpperCase(),
                  gender: item.student.gender,
                  birth_date: item.student.birth_date,
                  student: item.student,
                  academicYear: item.academic_year,
                  approved: item.is_approved,
                  isSelectable:
                    !item.is_approved && item.student.subjects_count >= 7,

                  isNew: item.student?.subjects_count == 0 ? true : false,
                }))
                // .sort(function(a, b) {
                //   if (a > b) return 1;
                //   return -1;
                // })
                .map((item, index) => ({
                  ...item,
                  index: ++index,
                }))
            : [];
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    exit() {
      return;
    },

    getSchoolClasses() {
      getClasses().then((response) => {
        this.schoolClassesLists = response.data[0]
          ? response.data[0].data
              .map((item) => ({
                ...item,
                schoolClass: item.school_class,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadStudents(data = null) {
      this.params.schoolClassId = data && data.id ? data.id : null;

      this.init(this.params);
      this.loadStreams(data);

      if (data === null) {
        this.schoolClass = {};
      }
    },

    loadStreams(data) {
      this.params.schoolClassId = data && data.id ? data.id : null;
      //this.loadStudentsByStreams(this.params);
      streams(this.params).then((response) => {
        //console.log("KKK", response.data[0]);
        this.streams = response.data[0]
          ? response.data[0]
              .map((item) => ({
                ...item,
              }))
              .sort(function(a, b) {
                return a.name > b.name ? 1 : -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadStudentsByStreams(data = null) {
      this.params.schoolClassStreamId = data && data.id ? data.id : null;
      this.init(this.params);
    },

    openDialog(data = null) {
      data.subjects = this.studentSubjects;

      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};

      //this.finishDataActions()
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    closeApprovalDialog() {
      this.item = null;
      this.isOpenApproval = false;
    },

    openDeleteDialog(item) {
      this.formData.subjectId = item;
      this.isOpen = true;
      this.item = item;
    },

    openApprovalDialog(item, action) {
      this.isOpenApproval = true;
      this.formData.approvingStudent = item;

      this.formData.approvingStudent.isApproved =
        action === "approving" ? true : false;

      if (action === "approving") {
        this.approvingTitle =
          "Are you sure you want to Approve this Registration? Once Approved you won't be able to update or Delete Subjects";
        this.formData.approvingStudent.isApproved = true;
      } else {
        this.approvingTitle =
          "Are you sure you want to disApprove this Registration? ";
        this.formData.approvingStudent.isApproved = false;
      }
    },

    deleteObject(data) {
      console.log(data);

      this.loading = true;

      let payload = {
        ...this.student,
        subjectId: data.id,
      };

      subject
        .remove(payload)
        .then(() => {
          this.finishDataActions(payload);
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    approveSubjects() {
      this.loading = true;

      subject
        .approveStudentSubjects(this.formData.approvingStudent)
        .then((response) => {
          if (response.status == 200) {
            this.isApproved = this.formData.approvingStudent.isApproved;

            this.loading = false;
            this.finishDataActions(this.formData.approvingStudent);
          }
          this.init(this.params);
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
          }
        });
    },

    searchOptions(optionName) {
      (this.params.search = optionName ? optionName : null),
        this.init({
          size: this.params.size,
          total: this.params.total,
          ...this.params,
        });
    },

    save(data) {
      this.loading = true;
      if (data.isNew) {
        subject
          .create(data) //create instead of update
          .then(() => {
            this.finishDataActions(data);
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        subject
          .update(data)
          .then(() => {
            this.finishDataActions(data);
            //this.init(this.params)
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
              this.isOpen = false;
            }
          });
      }
    },
    finishDataActions(data) {
      this.loadBySubject(data);
      this.loading = false;
      this.isOpen = false;
      this.closeApprovalDialog();
      this.closeDialog();
      this.init(this.params);
    },

    loadSubjects(data = null) {
      let params = {
        search: data ? data : null,
        size: 1000,
      };
      getSubjects(params).then((response) => {
        this.subjects = response.data[0].data.sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        });
      });
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        // this.init(this.params);
      },
      update() {
        this.loading = true;
        //this.init(this.params);
      },
      deep: true,
    },
  },

  computed: {
    headerSubjects() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "ids",
          class: "primary--text",
        },
        {
          text: "Subject Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Subject Level",
          align: "start",
          sortable: false,
          value: "level.name",
          class: "primary--text",
        },

        {
          text: "Paper 2 ?",
          align: "start",
          sortable: false,
          value: "multi_papers.name",
          class: "primary--text",
        },
        {
          text: "Subject Code",
          align: "start",
          sortable: false,
          value: "code",
          class: "primary--text",
        },
        {
          text: "Actions",
          align: this.isApproved === true ? " d-none" : "start",
          sortable: false,
          value: "actions",
          class: "primary--text",
        },
      ];
    },

    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        // this.init(this.params);
      },
    },
  },
  mounted() {
    this.getSchoolClasses();

    this.loadSubjects();
  },
};
</script>

<style scoped></style>
